.sectionfilm {
    margin-top: 80px;
    margin-bottom: 80px;
}
.image-film div a img {
    border-radius: 6px;
    max-width: 90%;
    margin-bottom: 50px;
}
.image-film div a img:hover {
    max-width: 94%;
}
.image-film div a {
}
.image-film div {
    padding: 0;
    text-align: center;
}
.page-item.active .page-link {
    background: #9a721d !important;
    color: #000;
}

.page-item .page-link {
    background: none !important;
    border-radius: 6px;
    border: 0px !important;
    color: #fff;
    padding: 10px 20px;
    margin: 0 10px;
}
.section-films {
    padding-top: 60px;
}
.nav-pills .nav-link {
    background: #9a721d !important;
    margin: 0 10px;
}
.dropdown-item-films {
    border-bottom: none !important;
}
.bginput {
    background: #c4c4c4;
}
.border-none {
    border: none !important;
}
/*.search-form {
    border: #666664 2px solid;
    border-radius: 6px;
}*/
button:focus,
button:active,
a:focus,
a:active,
input:focus,
input:active,
li:focus,
li:active,
.page-link:focus,
.page-link:active {
    outline: none !important;
    box-shadow: none;
}
@media screen and (max-width: 991px) {
    .image-film div {
        padding: inherit;
        margin: inherit;
    }
    .nav-pills .nav-link {
        margin: 0;
    }
    .nav-pills,
    .section-films h2 {
        margin-bottom: 24px;
    }
}
@media screen and (min-width: 990px) {
    .mobile-logo-display {
        display: none;
    }
    .nav-pills,
    .section-films h2 {
        display: inline-block !important;
    }
}
@media screen and (max-width: 575px) {
    .banner-bg {
        background-image: none;
    }
}
