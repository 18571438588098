/* @font-face{
    font-family:road_rageregular;
    src:url(road_rage.woff2) format('woff2'),url(road_rage.woff) format('woff');
    font-weight:400;
    font-style:normal
}
@font-face{
    font-family:panicstrickenmedium;
    src:url(panicstricken.woff2) format('woff2'),url(panicstricken.woff) format('woff');
    font-weight:400;
    font-style:normal */
/* } */
body {
    margin: 0;
    background: #000;
    background-image: url(https://kriscaron.com/projetfinal/arriere-plan.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Nunito Sans", sans-serif;
    color: #D9D8D2;
    font-size: 16px;
}
h1 {
    font-family: "Open Sans", sans-serif;
    color: #D9D8D2;
    font-size: 40px;
}
h2 {
    font-family: "Open Sans", sans-serif;
    color: #D9D8D2;
    font-size: 30px;
    margin: 0;
    padding: 0;
}
h2 span {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none !important;
    color: #D9D8D2;
}
a:hover {
    text-decoration: none;
    color: #D9D8D2;
}
.fa-heart:hover {
    color: #9a721d;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #9a721d;
}
.annuler-filtre {
    background: #9a721d;
    color: #fff !important;
    border: 0 !important;
    width: inherit !important;
    font-weight: 600 !important;
    font-size: 18px;
    display: inline-block !important;
    margin: 20px 0;
    padding: 8px 8px 8px 16px;
    border-radius: 6px;
}
.zonerecherche {
    background-color: #fff;
    border: #666664 2px solid;
    border-radius: 6px;
}
.zonerecherche .input-group-text{
    background-color: #fff !important;
    margin-top:3px;
}
.synopsis a {
    text-decoration: none !important;
    color: #D9D8D2 !important;
}
.synopsis a:hover {
    text-decoration: none !important;;
    color: #e6e6e6 !important;;
}
.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0 0;
    grid-template-areas: "Col1 Col2 Col3" "Col4 Col4 Col3" "Col5 Col6 Col7" "Col5 Col8 Col8";
}
.container * {
    border: 1px solid red;
    position: relative;
}
.container :after {
    content: attr(class);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.logo {
    max-width: 98px;
    margin-right: 32px;
}
.bg-rideaux {
    /* background:url(/arriere_plan_rideaux.jpg) no-repeat top center; */
    background-size: cover;
}

.billet{
    background-size: contain;
    background-position: center center;
    /*max-width:413px;*/
    text-align:center;
    margin: 0 6px;
    background-repeat: no-repeat;
    white-space: nowrap;
}
.billet{
    font-family: 'PT Serif', serif;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
    color: #736357;
    display: block;
    display: flex;
    align-items: center;
    height: 150px;
    justify-content: center;
    padding-right: 50px;
    
}
.billet:hover{
    color: #736357;   
}
.image-film a img{
    border-radius: 6px;
}
.image-film-poster{
    border-radius: 6px;
}
.modal-body{
    background-color: rgba(0, 0, 0, 0.0) !important;
}
.banner {
    /* background:url(../images/banner.jpg) no-repeat center center; */
    background-size: cover;
    height: 780px;
    display: flex;
    justify-content: center;
    align-items: end;
    margin-bottom: 90px !important;
}
.btn-nouveautes {
    background: #9a721d;
    color: #D9D8D2;
    font-family: "Nunito Sans", sans-serif;
    padding: 16px 30px;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 68px;
    display: block;
    border-radius: 4px;
    text-decoration: none;
    box-shadow: 0 0 10px 0 #000;
}
.btn-nouveautes:hover {
    color: #D9D8D2;
    text-decoration: none;
}
.txt-center {
    text-align: center;
}
.txt-right {
    text-align: right;
}
.section-site {
    padding-top: 30px;
    padding-bottom: 70px;
}
.zigzageffect img {
    border-radius: 6px;
}
.zigzageffect div div :nth-child(odd) img {
    margin-top: 90px;
}
.photo-acteur{
    margin-bottom: 20px;
}
.photo-film{
    margin-bottom: 20px;
}
.dropdown-menu {
    right: 0;
    z-index: 1000;
    display: none;
    float: right;
    min-width: inherit;
    background: #000;
}
.dropdown-menu-border {
    border: 2px solid #747578;
}
.border-none {
    border: none !important;
}
.dropdown-item {
    padding: 0;
    clear: both;
    font-weight: 400;
    color: #bdc4bc;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    padding: 14px 12px !important;
    border-bottom: 2px solid #747578;
    background: 0 0 !important;
}
.dropdown-item:hover {
    color: #fff;
}
.logofooter {
    max-width: 100px;
    margin-bottom: 14px;
}
.txt-open-sans {
    font-family: "Open Sans", sans-serif;
}
.txt-nunito {
    font-family: "Nunito Sans", sans-serif;
}
.txt-bold {
    font-weight: 700;
}
.txt-center {
    text-align: center;
}
.txt-white {
    color: #D9D8D2 !important;
}
.txt-gold {
    color: #9a721d;
}
.txt-black {
    color: #000;
}
.txt-16 {
    font-size: 16px;
    line-height: 16px;
}
.txt-18 {
    font-size: 18px;
    line-height: 18px;
}
.txt-20 {
    font-size: 20px;
    line-height: 20px;
}
.txt-22 {
    font-size: 22px !important;
    line-height: 22px !important;
}
.txt-28 {
    font-size: 28px;
    line-height: 28px;
}
.txt-30 {
    font-size: 30px;
    line-height: 30px;
}
.txt-36 {
    font-size: 36px;
}
.txt-40 {
    font-size: 40px;
    line-height: 40px;
}
.txt-50 {
    font-size: 50px;
    line-height: 50px;
}
.txt-60 {
    font-size: 60px;
    line-height: 60px;
}
.txt-64 {
    font-size: 64px;
}
.bg-gold:hover {
    background: #9a721d;
    color: #000;
    border: 1px solid #9a721d;
}
.bg-white{ 
    background: #D9D8D2;
}
.bg-black{ 
    background: #000;
}
.btn-black {
    background: #000;
    color: #fff;
    border: 2px solid #fff;
    padding: 8px 20px 8px 20px;
    font-family: "Bebas Neue", cursive;
    font-size: 28px;
    line-height: 28px;
    margin-top: 20px;
    display: inline-block;
    box-shadow: 0 0 10px 0 #000;
}
.btn-black:hover {
    color: #fff;
}
.btn-billet {
    color: #000;
    padding: 40px 0 0 0;
    font-family: "Bebas Neue", cursive;
    font-size: 24px;
    line-height: 28px;
    display: inline-block;
    width: 246px;
    /* background:url(../images/billet.jpg) no-repeat top center; */
    margin: 36px auto;
    height: 132px;
}
.footer {
    /* margin-top: 120px; */
    box-shadow: 0px -8px 8px 0 rgba(0, 0, 0, 0.6);
    padding: 25px 0;
    text-align: center;
}
.categories{
    padding: 10px 10px 10px 10px;
}
.categoriestop{
    padding: 60px 10px;
    background: rgba(0, 0, 0, 0.6)
}
.categoriecontent{
    padding: 10px 10px;
}
.categorieswrapper{
background-image: url(https://kriscaron.com/projetfinal/texture1.png);
background-repeat: repeat;
}
.fichefilmwrapper{
    background-image: url(https://kriscaron.com/projetfinal/texture1.png);
    background-repeat: repeat;
    }
.btn-link {
    margin: 0;
    padding: 0 20px !important;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
}
.icon-link {
    margin: 0;
    padding: 10px 15px 7px 15px !important;
    color: #d9d8d2;
    font-size: 16px;
    line-height: 16px;
}
.btn-active {
    color: #fff !important;
}
.menu-item-align-fa a,
.menu-item-align-fa li {
    display: inline-block;
    color: #bdc4bc;
    font-size: 32px;
}
.menu-item-align-fa a:hover {
    color: #fff;
}
.navbar {
    margin: 0 98px;
    padding: 15px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6)
}
.navbar-nav .nav-link {
    color: #bdc4bc !important;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
}
.navbar-nav .nav-link:hover {
    color: #9a721d !important;
}
.navbar-nav-droit {
    -ms-flex-direction: row;
    flex-direction: row;
}
.navbar-dark .navbar-toggler-icon {
    /* background-image:url(../images/menu_icon.png); */
    background-repeat: no-repeat;
    border: none;
}
.navbar-dark .navbar-toggler {
    border: none;
}
.section-padding-mobile {
    padding-bottom: 30px;
}
.border-none {
    border: none !important;
}
.bg-none {
    background: none !important;
}
button:focus,
button:active,
a:focus,
a:active,
input:focus,
input:active,
li:focus,
li:active,
.page-link:focus,
.page-link:active {
    outline: none !important;
    box-shadow: none;
}
.bouton-bande-annonce
{
    cursor: pointer;
  }
.force *{
    color: #D9D8D2 !important;
    font-size: 18px !important;
    font-family: Montserrat, sans-serif !important;
  }
.icone_sociaux_margin{
    margin-right: 14px;
  }
  .icone_sociaux_margin:hover{
    color: #9a721d;
    -webkit-text-fill-color: black; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #9a721d;
  }
  
  .fiche-acteur{
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0;
  }
  .select {
    background: #9a721d;
    color: #fff !important;
    border: 0 !important;
    width: inherit !important;
    font-weight: 600 !important;
    font-size: 18px;
    display: inline-block !important;
    margin: 0 20px;
    padding: 8px 8px 8px 16px;
    border-radius: 6px;
  }
  .option {
    background: #000;
    color: #D9D8D2;
    font-family: 'Nunito Sans',sans-serif;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #747578;
}
select {
    display: inline-block;
    font: inherit;
    /* reset */
    margin: 0;
    border: none;
    outline: none;    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  option:checked, option:hover {
    border: 0 !important;
    background: #9a721d !important;
}
select:focus > option:checked { 
    background: #9a721d !important;
}
select option:focus { 
    background: #9a721d !important;
}
select.minimal {
    background-image:
      linear-gradient(45deg, transparent 50%, white 50%),
      linear-gradient(135deg, white 50%, transparent 50%);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px);
    background-size:
      5px 5px,
      5px 5px;
    background-repeat: no-repeat;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  .slider img{
    border-radius:6px
}
.slider-margin-effect div div :nth-child(odd) img{
    margin-top:90px
}
.relative{
    position: relative !important;
}
.imgspacer a img{
    max-width: 100% !important;
}
.slider-margin-effect div div :nth-child(odd) {
    margin-top:60px
}
@media only screen and (min-width: 992px) {
    .menupadding {
      padding-left: 64px !important;
      padding-right: 64px !important;
    }
    h1 {
        padding-left: 64px !important;
      }
  }
@media screen and (max-width: 992px) {
    .banner {
        margin-bottom: 0 !important;
    }
    .footer {
        margin-top: 0;
        padding: 25px 0;
        text-align: center;
    }
    .section-site {
        padding-top: 30;
        padding-bottom: 30;
    }
    .txt-30-mobile {
        font-size: 30px !important;
        line-height: 30px !important;
    }
    .txt-22-mobile {
        font-size: 22px !important;
        line-height: 22px !important;
    }
    .txt-center-mobile {
        text-align: center;
    }
    .section-padding-mobile {
        padding: 52px 0;
    }
    .logo {
        max-width: 82px;
        margin-right: -56px;
    }
    .navbar {
        margin: 0;
    }
    .icon-link {
        padding: 10px !important;
    }
    .navbar-nav .nav-link {
        color: #bdc4bc;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        padding: 14px 12px !important;
        border-bottom: 2px solid #747578;
    }
    .navbar-nav {
        padding: 0 26px;
    }
}
.navbar-dark .navbar-toggler-icon {
    /* background-image:url(../images/menu_icon.png);
    background-repeat:no-repeat; */
    border: none;
}
.navbar-dark .navbar-toggler {
    border: none;
}
.navbar-nav-droit {
    -ms-flex-direction: row;
    flex-direction: row;
}
.banner-bg {
    /* background:url(../images/bg_section1.jpg) no-repeat center center; */
    background-size: cover;
}
.bg-white {
    background: #fff;
}
@media screen and (max-width: 991px) {
    .mobile-txt-center {
        text-align: center !important;
    }
    .mobile-logo-display {
        display: block;
    }
    .mobile-logo-display-banner {
        display: none;
    }
    .section2-bg {
        background-image: none;
    }
}
@media screen and (min-width: 990px) {
    .mobile-logo-display {
        display: none;
    }
    .mobile-logo-display-banner {
        display: block;
    }
}
@media screen and (max-width: 575px) {
    .banner-bg {
        background-image: none;
    }
    .select {
        background: #9a721d;
        color: #fff !important;
        border: 0 !important;
        width: inherit !important;
        font-weight: 600 !important;
        font-size: 18px;
        display: block !important;
        margin: 20px 0;
        padding: 8px 8px 8px 16px;
        border-radius: 6px;
      }
}



/* FORMUALIRE MODAL LOGIN*/

/* Full-width input fields */
.formulairemodal input[type=text], .formulairemodal input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  /* Set a style for all buttons */
  .formbutton {
    background-color: #9a721d;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  /* Extra styles for the cancel button */
  .cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
  }
  
  /* Center the image and position the close button */
  .imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
    position: relative;
  }
  
  img.avatar {
    width: 40%;
    border-radius: 50%;
  }
  
  .containerform {
    padding: 16px;
  }
  
  span.psw {
    padding-top: 16px;
  }
  
  /* The Modal (background) */
  .modalform {
    max-width: 400px;
    margin: auto;
  }
  .bgopacity {
    background-color: rgba(0,0,0,0.0);

  }
 .modal-content *{
    display: block;
    text-align: left;
  }
  .formconnexion{
    text-align: center;
  }
  /* Modal Content/Box */
  .modal-content {
    background-color: rgba(0, 0, 0, 0.0) !important;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */

    width: 80%; /* Could be more or less, depending on screen size */
    border: none !important;
  }
  
  /* The Close Button (x) */
  .close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: red;
    cursor: pointer;
  }
  
  /* Add Zoom Animation */
  .animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
  }
  
  @-webkit-keyframes animatezoom {
    from {-webkit-transform: scale(0)} 
    to {-webkit-transform: scale(1)}
  }
    
  @keyframes animatezoom {
    from {transform: scale(0)} 
    to {transform: scale(1)}
  }
  
  /* Change styles for span and cancel button on extra small screens */
  @media screen and (max-width: 300px) {
    span.psw {
       display: block;
       float: none;
    }
    .cancelbtn {
       width: 100%;
    }
  }

