.classement {
    padding: 4px 8px;
    border: #fff 1px solid;
    margin-right: 20px;
}
.genre {
    padding: 4px 8px;
    border: #9a721d 1px solid;
    color: #9a721d;
    margin-right: 20px;
    border-radius: 6px;
}
.genre:hover {
    color: white;
    border: 1px solid white;
}
.synopsis,
.synopsis p {
    color: #fff;
    font-size: 18px;
}
.bouton-bande-annonce {
    color: #000;
    font-size: 26px;
    background: #9a721d;
    border-radius: 6px;
    font-weight: bold;
    padding: 16px 24px;
    display: inline-block;
    margin-right: 48px;
    border: 2px solid #9a721d;
}
.bouton-bande-annonce:hover {
    color: #9a721d;
    background-color: rgba(0,0,0,0.5);
    border: 2px solid #9a721d;
}
.bouton-coeur {
    color: #9a721d;
    font-size: 42px;
    display: inline-block;
}
.fiche-films {
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-fiche-opacity {
    padding-top: 80px;
    padding-bottom: 120px;
    background: rgba(0, 0, 0, 0.8);
}
.card-text {
    color: #000;
    font-size: 16px;
}
.gutter-10.row {
    margin-right: -5px;
    margin-left: -5px;
}
.gutter-10 > [class^="col-"],
.gutter-10 > [class^=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
.card-body {
    padding: 6px;
}
.card-arrow {
    max-width: 30px !important ;
    margin: auto;
}

.image-film div a img {
    border-radius: 6px;
    max-width: 90%;
    margin-bottom: 50px;
}
.image-film div a {
}
.image-film div {
    padding: 0;
}

@media screen and (max-width: 991px) {
    .image-film div {
        padding: inherit;
        margin: inherit;
    }
}
@media screen and (min-width: 990px) {
    .mobile-logo-display {
        display: none;
    }
    .nav-pills,
    .section-films h2 {
        display: inline-block !important;
    }
}
@media screen and (max-width: 575px) {
    .banner-bg {
        background-image: none;
    }
}
